let awsRegion = 'us-west-2';

export default {
 api_urls: {
   reporting: 'https://ux8acvqkt2.execute-api.us-west-2.amazonaws.com/api',
   geojson: 'https://tlr9rgvymi.execute-api.us-west-2.amazonaws.com/api',
   overlays: 'https://ev97yv3ly9.execute-api.us-west-2.amazonaws.com/api',
 },
 google_api_url: 'AIzaSyDI0B09O3FFEn1-usWMdtJAzat9O7SbvR4',
 main_company_term_id: 6,
 crm_base_url: 'https://crm.staging.evolution.forwoodsafety.com',
 forwood_id_url: 'https://id.staging.evolution.forwoodsafety.com?redirect_uri=https://geoeditor.staging.evolution.forwoodsafety.com',
 custom_tile_url: 'https://mapoverlays.geoeditor.staging.evolution.forwoodsafety.com',
 Auth: {
   userPoolId: 'us-west-2_2vNscmUHY',
   userPoolWebClientId: '5k7u30kr1b3h0cgv6dvbnj92qc',
   cookieStorage: {
     domain: '.staging.evolution.forwoodsafety.com',
     secure: true,
   },
 },
 intercom_enabled: 'False',
};
